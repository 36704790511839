import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { useSpring, animated } from 'react-spring';
import dogeImage from './images/1.jpg';
import dogeConsterne from './images/2.jpg'; 
import doge2 from './images/3.jpg'; 
import doge3 from './images/4.jpg'
import doge4 from './images/5.jpg'; 
import doge5 from './images/6.gif';
import twitterImage from './images/twitter.png';
import telegramImage from './images/telegramicon.png';
import dexscreenerImage from './images/dexscreener.png';
import dextoolsImage from './images/dextools.png';
import doge6 from './images/7.jpg';
import doge7 from './images/8.jpg';
import doge8 from './images/9.gif';
import doge9 from './images/10.webp';
import doge4bis from './images/4.jpg';
import MusicPlayer from './MusicPlayer';
import './App.css';
const WelcomeModal = ({ onClose }) => {
    return (
        <div className="welcome-modal">
            <div className="welcome-content">
                <h2>LEGEND KABORIP</h2>
                <button className="welcome-button" onClick={onClose}>Enter</button>
            </div>
        </div>
    );
};

function GalleryItem({ image, alt }) {
    return (
        <div className="gallery-item">
            <img src={image} alt={alt} className="gallery-image" />
        </div>
    );
}

const BackgroundMusic = ({ playMusic }) => {
    const audioRef = useRef(null);

    useEffect(() => {
        if (playMusic) {
            audioRef.current.play();
        }
    }, [playMusic]);



    return (
        <div className="music-container">
            <audio ref={audioRef} src="/puzzle.mp3" loop />

        </div>
    );
};

function App() {
    const [showWelcomeModal, setShowWelcomeModal] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleCloseModal = () => {
        setShowWelcomeModal(false);
        setIsPlaying(true); // Start playing music when user enters
    };

    return (
        <Router>
            <div className="App">
                {showWelcomeModal && <WelcomeModal onClose={handleCloseModal} />}
                <HeartSprinkles />
                <div id="background-container">
                    <div id="item1" className="background-item">
                        <img src={doge6} alt="Fighting GIF" />
                    </div>
                    <div id="item2" className="background-item">
                        <img src={doge7} alt="Glitter Princess GIF" />
                    </div>
                    <div id="item3" className="background-item">
                        <img src={doge8} alt="Twilight Princess GIF" />
                    </div>
                    <div id="item4" className="background-item">
                        <img src={doge9} alt="Overworld" />
                    </div>
                    <div id="item5" className="background-item">
                        <img src={doge4bis} alt="Deadlove" />
                    </div>
                </div>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                </Routes>
                <footer className="App-footer">
                    <div className="footer-content">
                        <p>&copy; KABORIP 2024. All rights reserved.</p>
                    </div>
                </footer>
                <MusicPlayer isPlaying={isPlaying} />
            </div>
        </Router>
    );
}


function HomePage({ playMusic }) {
    return (
        <main>
            <BackgroundMusic playMusic={playMusic} />
            <AnimatedElement>
                <div className="title-container">
                    <h2 className="animated-title">KABORIP</h2>
                </div>
            </AnimatedElement>
            <AnimatedElement>
                <div className="image-container">
                    <img src={dogeImage} alt="KABORIP" className="reveal-image reveal" />
                </div>
            </AnimatedElement>
            <AnimatedElement>
                <div className="cat-in-a-pan-container">
                    <p className="just-a-cat-in-a-pan">The happiest Doge on earth ❤️</p>
                </div>
            </AnimatedElement>
            <AnimatedElement>
                <p className="copy-text">CA: </p>
            </AnimatedElement>

            <AnimatedElement>
                <div className="buy-button">
                    <a href="https://pump.fun/" target="_blank" rel="noopener noreferrer">BUY NOW</a>
                </div>
            </AnimatedElement>
            <SocialMediaLinks />
            <CustomSection />
            <TokenomicsSection />
            <ResourcesSection />
            <AnimatedElement>
            </AnimatedElement>

        </main>
    );
}

const HeartSprinkles = () => {
    useEffect(() => {
        const decorationImages = [
            'url("https://img.icons8.com/emoji/48/000000/red-heart.png")',
            'url("https://img.icons8.com/emoji/48/000000/purple-heart.png")',
            'url("https://img.icons8.com/emoji/48/000000/yellow-heart.png")',
            'url("https://img.icons8.com/emoji/48/000000/blue-heart.png")',
            'url("https://img.icons8.com/emoji/48/000000/green-heart.png")',
            'url("https://img.icons8.com/emoji/48/000000/sparkling-heart.png")',
            'url("https://img.icons8.com/emoji/48/000000/heart-with-arrow.png")',
            'url("https://img.icons8.com/emoji/48/000000/crown.png")',
            'url("https://img.icons8.com/emoji/48/000000/magic-wand.png")'
        ];

        const createDecoration = (x, y) => {
            const decoration = document.createElement('div');
            decoration.className = 'decoration';
            decoration.style.left = `${x}px`;
            decoration.style.top = `${y}px`;
            decoration.style.backgroundImage = decorationImages[Math.floor(Math.random() * decorationImages.length)];
            document.body.appendChild(decoration);

            setTimeout(() => {
                decoration.remove();
            }, 2000);
        };

        const handleMouseMove = (event) => {
            createDecoration(event.clientX, event.clientY);
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return null;
};

function SocialMediaLinks() {
    return (
        <div className="social-icons">
            <AnimatedElement>
                <a href="https://twitter.com/kaborip" target="_blank" rel="noopener noreferrer" className="social-icon twitter">
                    <img src={twitterImage} alt="Twitter Icon" />
                </a>
            </AnimatedElement>
            <AnimatedElement>
                <a href="https://dexscreener.com/" target="_blank" rel="noopener noreferrer" className="social-icon dexscreener">
                    <img src={dexscreenerImage} alt="Dexscreener Icon" />
                </a>
            </AnimatedElement>
            <AnimatedElement>
                <a href="https://t.me/kaborip" target="_blank" rel="noopener noreferrer" className="social-icon telegram">
                    <img src={telegramImage} alt="Telegram Icon" />
                </a>
            </AnimatedElement>
            <AnimatedElement>
                <a href="https://www.dextools.io" target="_blank" rel="noopener noreferrer" className="social-icon dextools">
                    <img src={dextoolsImage} alt="Dextools Icon" />
                </a>
            </AnimatedElement>
        </div>
    );
}

function AnimatedElement({ children }) {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            {
                threshold: 0.2,
            }
        );

        observer.observe(ref.current);

        const currentRef = ref.current;

        return () => {
            observer.unobserve(currentRef);
        };
    }, []);

    const springProps = useSpring({
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? 'translateY(0px)' : 'translateY(20px)',
    });

    return <animated.div ref={ref} style={springProps}>{children}</animated.div>;
}

function CustomSection() {
    const scrollToResources = () => {
        const resourcesSection = document.getElementById('resources-section');
        if (resourcesSection) {
            resourcesSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <AnimatedElement>
            <section className="custom-section">
                <div className="gallery">
                    <GalleryItem
                        image={doge3}
                        alt="KABORIP"
                    />
                    <GalleryItem
                        image={doge2}
                        alt="KABORIP"
                    />
                    <GalleryItem
                        image={dogeConsterne}
                        alt="KABORIP"
                    />
                    <GalleryItem
                        image={doge4}
                        alt="KABORIP"
                    />
                    <GalleryItem
                        image={doge5}
                        alt="KABORIP"
                    />
                </div>
                <button className="navigate-button" onClick={scrollToResources}>Resources</button>
            </section>
        </AnimatedElement>
    );
}

function TokenomicsSection() {
    return (
        <AnimatedElement>
            <section className="tokenomics">
                <h2>Tokenomics</h2>
                <div className="tokenomics-details">
                    <TokenomicsItem title="Total Supply" value="1,000,000,000" image={dogeImage} />
                    <TokenomicsItem title="LP Burned" value="100%" image={dogeImage} />
                    <TokenomicsItem title="Tax" value="0%" image={dogeImage} />
                </div>
            </section>
        </AnimatedElement>
    );
}

function TokenomicsItem({ title, value, image }) {
    return (
        <div className="tokenomics-item">
            <img src={image} alt={title} className="tokenomics-image" />
            <h3>{title}</h3>
            <p>{value}</p>
        </div>
    );
}

function ResourcesSection() {
    return (
        <AnimatedElement>
            <section id="resources-section" className="resources-section">
                <h2>Resources</h2>
                <div className="resource-items">
                    <ResourceItem name= "Happy Doge" image={dogeImage} />
                    <ResourceItem name="Doge in couch" image={dogeConsterne} />
                </div>
            </section>
        </AnimatedElement>
    );
}

function ResourceItem({ name, image }) {
    function downloadImage(imagePath) {
        const link = document.createElement('a');
        link.href = imagePath;
        link.download = imagePath.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div className="resource-item">
            <p className="resource-name">{name}</p>
            <div className="resource-preview">
                <img
                    src={image}
                    alt={`${name} preview`}
                    className="resource-image"
                />
            </div>
            <div className="resource-details">
                <button
                    className="download-button"
                    onClick={() => downloadImage(image)}
                >
                    Download
                </button>
            </div>
        </div>
    );
}

export default App;
