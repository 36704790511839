import React, { useState, useEffect, useRef } from 'react';
import './MusicPlayer.css';

const tracks = [
  {
    title: 'Lost Woods - Ocarina of Time',
    artist: 'Unknown',
    url: `${process.env.PUBLIC_URL}/music/lostwoods.mp3`,
  },
  {
    title: 'Loose Cannon',
    artist: 'Puzzle',
    url: `${process.env.PUBLIC_URL}/music/puzzle.mp3`,
  },
  {
    title: 'Streets of Elegance',
    artist: 'HoyoMix',
    url: `${process.env.PUBLIC_URL}/music/StreetsofElegance.mp3`,
  },
];

const MusicPlayer = ({ isPlaying }) => {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    }
  }, [isPlaying]);

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const handleNext = () => {
    audioRef.current.pause();
    const nextTrackIndex = (currentTrackIndex + 1) % tracks.length;
    setCurrentTrackIndex(nextTrackIndex);
    audioRef.current.src = tracks[nextTrackIndex].url;
    audioRef.current.load();
    audioRef.current.addEventListener('canplaythrough', () => {
      audioRef.current.play();
    }, { once: true });
  };

  const handlePrev = () => {
    audioRef.current.pause();
    const prevTrackIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
    setCurrentTrackIndex(prevTrackIndex);
    audioRef.current.src = tracks[prevTrackIndex].url;
    audioRef.current.load();
    audioRef.current.addEventListener('canplaythrough', () => {
      audioRef.current.play();
    }, { once: true });
  };

  return (
    <div className="music-player">
      <div className="winamp-skin">
        <div className="display">
          <div className="title">{tracks[currentTrackIndex].title}</div>
          <div className="artist">{tracks[currentTrackIndex].artist}</div>
        </div>
        <div className="controls">
          <button onClick={handlePrev}>&lt;&lt;</button>
          <button onClick={handlePlayPause}>||</button>
          <button onClick={handleNext}>&gt;&gt;</button>
        </div>
      </div>
      <audio ref={audioRef} src={tracks[currentTrackIndex].url} onEnded={handleNext} />
    </div>
  );
};

export default MusicPlayer;
